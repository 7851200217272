import { Component } from "react";
import { withRouter } from "react-router-dom";

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    // console.log(this.props, prevProps);
    if (checkLocationPathPrefix(this.props, prevProps)) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

function checkLocationPathPrefix(props, prevProps) {
  const propPrefix = props.location.pathname.split("/")[1];
  const prevPropPrefix = prevProps.location.pathname.split("/")[1];
  return propPrefix != prevPropPrefix;
}

export default withRouter(ScrollToTop);
