import React from "react";
import { Link } from "react-router-dom";

// import Csss
import "./Index.css";

// import Imaeg
import Image from "./../../images/bg-img06.jpg";
import Image1 from "./../../images/bg-img3.jpg";
import Image2 from "./../../images/bg-img4.jpg";
import Image3 from "./../../images/bg-img5.jpg";
import Image4 from "./../../images/bg-img6.jpg";
import Image5 from "./../../images/bg-img5.jpg";

const Index = () => {
	return (
		<section id="demo">
			<div className="demo container-fluid text-center">
				<div className="login">
					<a
						href={process.env.REACT_APP_ADMIN_URL}
						target="_blank"
						className="btn btn-primary"
					>
						Admin Login
          </a>
				</div>
				<h1>Do You Have Website?</h1>
				<p className="under-line">If Not</p>
				<span>Look Our Demo</span>

				<div className="row mt-3">
					<div className="col-md-4">
						<div className="content-holder">
							<Link to="#">
								<div
									className="bg-stretch2"
									style={{ backgroundImage: `url(${Image})` }}
								>
									<div className="overlay">
										<div className="text-holder">
											<h3>View Demo</h3>
										</div>
									</div>
								</div>
							</Link>
						</div>
					</div>

					<div className="col-md-4">
						<div className="content-holder">
							<Link to="#">
								<div
									className="bg-stretch2"
									style={{ backgroundImage: `url(${Image1})` }}
								>
									<div className="overlay">
										<div className="text-holder">
											<h3>View Demo</h3>
										</div>
									</div>
								</div>
							</Link>
						</div>
					</div>

					<div className="col-md-4">
						<div className="content-holder">
							<Link to="#">
								<div
									className="bg-stretch2"
									style={{ backgroundImage: `url(${Image2})` }}
								>
									<div className="overlay">
										<div className="text-holder">
											<h3>View Demo</h3>
										</div>
									</div>
								</div>
							</Link>
						</div>
					</div>

					<div className="col-md-4 mt-2">
						<div className="content-holder">
							<Link to="#">
								<div
									className="bg-stretch2"
									style={{ backgroundImage: `url(${Image3})` }}
								>
									<div className="overlay">
										<div className="text-holder">
											<h3>View Demo</h3>
										</div>
									</div>
								</div>
							</Link>
						</div>
					</div>

					<div className="col-md-4 mt-2">
						<div className="content-holder">
							<Link to="#">
								<div
									className="bg-stretch2"
									style={{ backgroundImage: `url(${Image4})` }}
								>
									<div className="overlay">
										<div className="text-holder">
											<h3>View Demo</h3>
										</div>
									</div>
								</div>
							</Link>
						</div>
					</div>

					<div className="col-md-4 mt-2">
						<div className="content-holder">
							<Link to="#">
								<div
									className="bg-stretch2"
									style={{ backgroundImage: `url(${Image5})` }}
								>
									<div className="overlay">
										<div className="text-holder">
											<h3>View Demo</h3>
										</div>
									</div>
								</div>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Index;
