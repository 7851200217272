import React from "react";
import parse from "html-react-parser";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CareerVacancies from "../1";
import CloseRounded from "@material-ui/icons/CloseRounded";
import injectSheet from "react-jss";
import { styled } from "@material-ui/core/styles";

// style
const style = (theme) => ({
  root: {
    width: "100%",
    "& .MuiAccordion-root": {
      boxShadow: "none",
    },
    "& .MuiAccordion-rounded:first-child": {
      borderTopLeftRadius: "15px",
      borderTopRightRadius: "15px",
    },
    "& .MuiAccordion-rounded:last-child": {
      borderBottomLeftRadius: "15px",
      borderBottomRightRadius: "15px",
    },
    "& .MuiAccordion-root.Mui-expanded:first-child": {
      borderBottomLeftRadius: "15px",
      borderBottomRightRadius: "15px",
    },
    "& .MuiAccordion-root.Mui-expanded:last-child": {
      borderTopLeftRadius: "15px",
      borderTopRightRadius: "15px",
    },
    "& .MuiAccordion-root.Mui-expanded": {
      borderRadius: "15px",
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
      background: `${theme.colors.primary}`,
      borderRadius: "15px 15px 0px 0px",
      color: "#fff",
      "& .MuiSvgIcon-root": {
        fill: "#fff",
      },
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      "& .job-title": {
        color: "#fff",
      },
      "& .job-sub-title": {
        color: "#fff",
      },
      "& .deadline": {
        color: "#fff",
      },
    },
    "& .MuiAccordionDetails-root": {
      padding: "20px 16px",
      display: "block",
      "& .job-description": {
        "& h6": {
          fontSize: "16px",
          fontWeight: 700,
          color: "#434343",
          marginBottom: "15px",
        },
        "& p": {
          fontSize: "16px",
          color: "#73738c",
          "& strong": {
            fontWeight: "bold",
            color: "#434343",
          },
        },
        "& ul": {
          "& li": {
            color: "#73738c",
            fontSize: "16px",
            "&:not(:last-child)": {
              marginBottom: "10px",
            },
          },
        },
      },
      "& .education-level": {
        marginBottom: "20px",
        "& ul": {
          margin: "0",
          padding: "0",
          "& h6": {
            fontSize: "16px",
            fontWeight: 700,
            color: "#434343",
            marginBottom: "15px",
          },
          "& li": {
            listStyle: "none",
            color: "#73738c",
            position: "relative",
            paddingLeft: "15px",
            "&::before": {
              content: "''",
              position: "absolute",
              left: "0",
              top: "50%",
              transform: "translate(0, -50%) rotate(45deg)",
              width: "5px",
              height: "5px",
              background: theme.colors.primary,
            },
          },
        },
      },
      "& .salary": {
        "& ul": {
          margin: "0",
          padding: "0",
          "& h6": {
            fontSize: "16px",
            fontWeight: 700,
            color: "#434343",
            marginBottom: "15px",
          },
          "& li": {
            listStyle: "none",
            color: "#73738c",
            position: "relative",
            paddingLeft: "15px",
            "&::before": {
              content: "''",
              position: "absolute",
              left: "0",
              top: "50%",
              transform: "translate(0, -50%) rotate(45deg)",
              width: "5px",
              height: "5px",
              background: theme.colors.primary,
            },
          },
        },
      },
    },
    "& .marginBottom": {
      marginBottom: "30px",
      "& .MuiPaper-root": {
        boxShadow: "0 10px 50px rgb(166 209 237 / 20%)",
      },
    },
    "& .MuiAccordionSummary-content": {
      display: "block",
      margin: "30px 0",
      color: "#434343",
      "& .job-title": {
        fontSize: "16px",
        fontWeight: 500,
        marginBottom: "10px",
      },
      "& .job-sub-title": {
        display: "flex",
        alignItems: "center",
        color: "#73738c",
        fontSize: "16px",
        fontWeight: 500,
        marginBottom: "20px",
        "& .time": {
          marginRight: "10px",
        },
        "& .experience": {
          marginRight: "10px",
        },
        "& .job-number": {},
      },
      "& .deadline": {
        fontSize: "16px",
        fontWeight: 500,
        color: "#434343",
      },
    },
  },
  careerVacanciesBg: {
    position: "relative",
    paddingTop: "50px",
  },
  careerVacanciesMain: {
    "& .content-holder": {
      "& .text-holder": {
        textAlign: "center",
        "& .title": {
          color: "#434343",
          fontSize: "32px",
          fontWeight: 700,
          letterSpacing: "2px",
          display: "inline-block",
          position: "relative",
          "& .fa": {
            color: theme.colors.primary,
            fontSize: "42px",
          },
          "& .bullHorn": {
            transform: "rotate(180deg) rotateX(180deg)",
          },
        },
      },
    },
  },
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: "60px 20px 20px 20px",
    borderTop: "none",
    borderBottom: "none",
    border: "none",
    "& .border-content": {
      padding: "20px",
      border: `2px solid ${theme.palette.primary.main}`,
    },
  },
  "& .MuiPaper-root": {
    margin: "20px 0 ",
    boxShadow: "none",
    borderRadius: "15px",
    overflowY: "unset",
  },
  "& .MuiBackdrop-root": {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
  },
  "& .MuiTypography-root": {
    padding: 0,
  },
  "& .MuiButtonBase-root": {
    color: "#fff",
    background: theme.palette.primary.main,
    boxShadow: "none",
    height: "30px",
    width: "30px",
    borderRadius: "50%",
    minWidth: "unset",
    position: "absolute",
    right: "0px",
    top: "0",
    opacity: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiSvgIcon-root": {
      fontSize: "18px",
    },
    "&:hover": {
      background: theme.palette.secondary.main,
      color: "#fff",
      boxShadow: "none",
    },
  },
  "@media(max-width: 600px)": {
    "& .MuiDialogContent-root": {
      minWidth: "100%",
    },
    "& .MuiPaper-root": {
      maxWidth: "100%",
    },
  },
  "@media(min-width: 992px)": {
    "& .MuiDialogContent-root": {
      minWidth: "700px",
    },
    "& .MuiPaper-root": {
      maxWidth: "700px",
    },
  },
}));

const CareerVacancies2 = ({ classes, data }) => {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("body");
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panelIndex) => (event, isExpanded) => {
    setExpanded(isExpanded ? "panel" + panelIndex : false);
  };
  return (
    <>
      {data && data.length ? (
        <section className={`${classes.careerVacanciesBg}`}>
          <div className={`container-fluid ${classes.careerVacanciesMain}`}>
            <div className="content-holder">
              <div className="text-holder mb-5">
                <h2 className="title">
                  <i className="fa fa-bullhorn mr-3" />
                  Vacancy Announcement
                  <i className="fa fa-bullhorn ml-3 bullHorn" />
                </h2>
              </div>
              <div className={classes.root}>
                <div className="row">
                  {data.map((item, i) => {
                    return (
                      <div
                        className="col-lg-6 col-md-12 col-sm-12 marginBottom"
                        key={i + "____"}
                      >
                        <Accordion
                          expanded={expanded === "panel" + i}
                          onChange={handleChange(i)}
                          key={i++}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <div className="job-title">{item.job_title}</div>
                            <div className="job-sub-title">
                              <div className="time">
                                Type: {item.employment_type} Time
                              </div>
                              <div className="experience">
                                Min.Experience: {item.experience_required} Years
                              </div>
                              <div className="job-number">
                                No.of Vacancies: {item.no_of_vacancy}
                              </div>
                            </div>
                            <div className="deadline">
                              Deadline: {item.deadline}
                            </div>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="education-level">
                              <h6>Education Level </h6>
                              <ul>
                                <li>{item.education_level}</li>
                              </ul>
                            </div>
                            <div className="job-description">
                              <h6>Job Description </h6>
                              {parse(item.job_description)}
                            </div>
                            <div className="salary">
                              <h6>Salary </h6>
                              <ul>
                                <li>{item.salary}</li>
                              </ul>
                            </div>
                            <div className="career-modal text-right">
                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleClickOpen}
                                className={`${classes.outlineButton}`}
                              >
                                Apply Now
                              </Button>
                              <BootstrapDialog
                                onClose={handleClose}
                                aria-labelledby="customized-dialog-title"
                                open={open}
                                scroll={scroll}
                              >
                                <DialogContent dividers={scroll === "body"}>
                                  <CareerVacancies career_id={item.id} />
                                </DialogContent>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={handleClose}
                                >
                                  <CloseRounded />
                                </Button>
                              </BootstrapDialog>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
};

export default injectSheet(style)(CareerVacancies2);
