import * as yup from "yup";

let AdmissionSchema = yup.object().shape({
  name: yup.string().required("Name Is Required"),
  phone: yup.number().required("Phone Number Is Required"),
  email: yup.string().email("Enter Valid Email").required("Email Is Required"),
  message: yup.string().nullable(),
  program_id: yup.number().required("Program Id Is Required"),
});

let SubscribeSchema = yup.object().shape({
  email: yup.string().email("Enter Valid Email").required("Email Is Required"),
});

let PasswordProtectedScema = yup.object().shape({
  password: yup.string().nullable("Password Required."),
  // .min(8, "Password is too short - should be 8 chars minimum.")
  // .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
});

export { AdmissionSchema, SubscribeSchema, PasswordProtectedScema };
