import { createBrowserHistory } from "history";
// import { createHashHistory } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import { routerMiddleware } from "connected-react-router";

// for handling asyncrhonous api calls
import thunk from "redux-thunk";

// root reducer
import createRootReducer from "../reducers/index";

export const history = createBrowserHistory();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; //enabling chrome devtool

export default function configureStore(preloadedState) {
    const store = createStore(
        createRootReducer(history), // root reducer with router state
        preloadedState,
        composeEnhancers(
            applyMiddleware(
                thunk,
                routerMiddleware(history) // for dispatching history actions
                // ... other middlewares ...
            )
        )
    );

    return store;
}
