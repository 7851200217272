import React from "react";
import parse from "html-react-parser";
import injectSheet from "react-jss";
import PageTitle from "../../page-title";
import SideNav1 from "../../sidenav";
import MillsberryClzDetailPage from "../../millsberry-college/millsberry-clz-detail-page";
import RpsharmaDetailPage from "../../rpsharma-website/rpsharma-detail-page";
import CareerVacancies4 from "../../career-vacancies/4";
import AitmBannerImage from "../../aitm-banner-image";
import PasswordProtected from "components/password-protected";
import MainData from "./Main-data";

const styles = (theme) => ({
  mainContent: {
    overflowX: "auto",
    "& .mainImg": {
      width: "100% !important",
      maxWidth: (props) => {
        if (props.data[0].image_width) {
          return props.data[0].image_width + "px !important";
        }
      },
      float: (props) => {
        if (props.data[0].image_position) {
          return props.data[0].image_position;
        }
      },
      margin: (props) => {
        if (props.data[0].image_position) {
          if (props.data[0].image_position === "left") {
            return "0 15px 15px 0";
          } else {
            return "0 0 15px 15px";
          }
        }
      },
    },
    "& .description": {
      lineHeight: 1.8,
      fontSize: "18px",
      color: "#434343",
      "& h3": {
        "& span": {
          fontFamily: "unset !important",
        },
      },
      "& p": {
        lineHeight: 1.8,
        fontSize: "18px",
        color: "#434343",
        "& span": {
          fontFamily: "unset !important",
          fontSize: "18px",
          color: "#434343",
        },
      },
    },
    "& .buttons-holder": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      borderTop: "1px solid #e6e6e6",
      borderBottom: "1px solid #e6e6e6",
      padding: "15px 0",
      "& .share-buttons": {
        "& .share-text": {
          fontSize: "18px",
          fontWeight: "500",
          marginRight: "10px",
        },
        "& .react-share__ShareButton": {
          "&:not(:last-child)": {
            marginRight: "10px",
          },
        },
      },
      "& .views": {
        color: "#73738c",
        display: "flex",
        alignItems: "center",
        "& span": {
          marginLeft: "5px",
        },
      },
    },
  },
  mainContent1: {
    position: "relative",
    "& .mainImg": {
      width: "100% !important",
      maxWidth: (props) => {
        if (props.data[0].image_width) {
          return props.data[0].image_width + "px !important";
        }
      },
      float: (props) => {
        if (props.data[0].image_position) {
          return props.data[0].image_position;
        }
      },
      margin: (props) => {
        if (props.data[0].image_position) {
          if (props.data[0].image_position === "left") {
            return "0 15px 15px 0";
          } else {
            return "0 0 15px 15px";
          }
        }
      },
    },
    "& .description": {
      lineHeight: 1.8,
      fontSize: "18px",
      color: "#434343",
      "& h3": {
        "& span": {
          fontFamily: "unset !important",
        },
      },
      "& p": {
        "& span": {
          fontFamily: "unset !important",
          fontSize: "18px",
          color: "#434343",
        },
      },
    },
  },
  mainContent2: {
    overflowX: "auto",
    width: "100%",
    paddingLeft: "300px",
    position: "relative",
    "& .mainImg": {
      width: "100% !important",
      maxWidth: (props) => {
        if (props.data[0].image_width) {
          return props.data[0].image_width + "px !important";
        }
      },
      float: (props) => {
        if (props.data[0].image_position) {
          return props.data[0].image_position;
        }
      },
      margin: (props) => {
        if (props.data[0].image_position) {
          if (props.data[0].image_position === "left") {
            return "0 15px 15px 0";
          } else {
            return "0 0 15px 15px";
          }
        }
      },
    },
    "& .description": {
      lineHeight: 2,
      fontSize: "18px",
      color: "#434343",
    },
  },
  detailBg: {
    position: "relative",
  },
  sideBarList1: {
    position: "relative",
    background: "linear-gradient(to right, #550BA2 0%, #7d5ff9 100%)",
    zIndex: 1,
    height: "100%",
    padding: "0 20px 50px 60px",
    "&::before": {
      content: "''",
      position: "absolute",
      top: "-360px",
      left: "0",
      width: "100%",
      height: "370px",
      background: "linear-gradient(to right, #550BA2 0%, #7d5ff9 100%)",
    },
  },
  "@media(max-width: 600px)": {
    sideBarList1: {
      display: "none",
    },
    mainContent1: {
      width: "100%",
      paddingLeft: 0,
    },
    detailBg: {
      padding: "15px 0 15px 15px",
    },
  },
  "@media(min-width:768px)": {
    mainContent1: {
      width: "100%",
      paddingLeft: 0,
    },
    sideBarList1: {
      display: "none",
    },
    detailBg: {
      padding: "15px 0 15px 15px",
    },
  },
  "@media(min-width:992px)": {
    mainContent: {
      "& .mainImg, .left, .right": {
        float: "none !important",
      },
    },
    sideBarList: {
      maxWidth: 300,
    },
    sideBarList1: {
      display: "block",
    },
    millsberryContainer: {
      maxWidth: "100%",
      paddingLeft: 0,
    },
    detailBg: {
      padding: "0",
    },
  },
});

const Detail = ({
  contentKey,
  title,
  base_slug,
  data,
  resource_path,
  related_data,
  match,
  theme,
  classes,
  socialMedia,
}) => {
  const mainData = data[0];
  let files = mainData.file ? mainData.file.split(",") : [];
  const millsBerryDomains = ["millsberry.com.np", "millsberry.edu.np"];
  const isRpSharma = theme.domain === "rajendraphuyal.com.np";
  const isBannerImage =
    theme.domain === "lna.edu.np" ||
    theme.domain === "spa.edu.np" ||
    theme.domain === "bgmsm.edu.np";
  const spa = theme.domain === "spa.edu.np";
  const schoolDomain = theme.domain;
  const shareUrl = `${schoolDomain}${base_slug}${data[0].id}`;

  if (mainData.is_protected)
    return (
      <PasswordProtected
        mainData={mainData}
        resource_path={resource_path}
        shareUrl={shareUrl}
        spa={spa}
        isBannerImage={isBannerImage}
        classes={classes}
      />
    );

  return (
    <>
      {isRpSharma ||
      millsBerryDomains.includes(theme.domain) ||
      isBannerImage ? (
        <>
          {isBannerImage || isRpSharma ? (
            <>
              {isBannerImage ? (
                <>
                  {mainData.image ? (
                    <AitmBannerImage
                      image={resource_path + mainData.image}
                      theme={theme}
                    />
                  ) : theme.banner ? (
                    <AitmBannerImage
                      image={theme.resourcePath + "banner/" + theme.banner}
                      theme={theme}
                    />
                  ) : null}
                </>
              ) : (
                <RpsharmaDetailPage
                  image={theme.resourcePath + "banner/" + theme.banner}
                  title="Contact Us"
                />
              )}
            </>
          ) : (
            <>
              {mainData.image ? (
                <MillsberryClzDetailPage
                  image={resource_path + mainData.image}
                />
              ) : theme.banner ? (
                <MillsberryClzDetailPage
                  image={theme.resourcePath + "banner/" + theme.banner}
                />
              ) : null}
            </>
          )}
        </>
      ) : null}
      <div
        className={
          millsBerryDomains.includes(theme.domain)
            ? `${classes.millsberryContainer} container-fluid`
            : "container-fluid"
        }
      >
        {millsBerryDomains.includes(theme.domain) ? (
          contentKey === "singleCareer" ? (
            <CareerVacancies4 data={data} />
          ) : (
            <div className={`${classes.detailBg}`}>
              <div className="row pt-5">
                <div className="col-lg-3 col-md-12 col-sm-12">
                  {contentKey || related_data ? (
                    <div className={`sidebar-block ${classes.sideBarList1}`}>
                      <SideNav1
                        relatedData={related_data}
                        title={title}
                        base_slug={base_slug}
                        match={match}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="col-lg-9 col-md-12 col-sm-12 mb-5">
                  <div className={`text-block ${classes.mainContent1}`}>
                    <PageTitle title={mainData.title} />
                    <div className="description">
                      {mainData.description
                        ? parse(mainData.description)
                        : null}
                    </div>
                    {files.map((file, index) => (
                      <a
                        href={resource_path + file}
                        className="btn btn-primary mr-2"
                        target="_blank"
                        rel="noopener noreferrer"
                        key={index}
                      >
                        <i className="fa fa-download mr-1" />{" "}
                        {file.lastIndexOf("-file-") !== -1
                          ? file.slice(0, file.lastIndexOf("-file-"))
                          : "Download File"}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )
        ) : (
          <div className="single-block">
            <div className="row">
              <MainData
                files={files}
                mainData={mainData}
                resource_path={resource_path}
                shareUrl={shareUrl}
                spa={spa}
                isBannerImage={isBannerImage}
                classes={classes}
              />
              {contentKey || related_data ? (
                <div
                  className={`col-md d-none d-md-block ${classes.sideBarList}`}
                >
                  <SideNav1
                    relatedData={related_data}
                    title={title}
                    base_slug={base_slug}
                    match={match}
                    spa={spa}
                  />
                </div>
              ) : null}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default injectSheet(styles)(Detail);
