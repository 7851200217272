import React from 'react';

// css
import "./../21/index.css";

// image
import Image from "images/preschool-logo.png";

// react jss
import injectSheet from "react-jss";

// style
const style = ({
    loaderBg: {
        background: "linear-gradient(45deg, #7d5ff9 0%, #675fef 43%, #2876bd 92%)",
        padding: "100px 0",
        overflow: 'hidden',
        position: 'relative',
        height: "100vh",
        width: "100%",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    LoaderMain: {
        "& .content-holder": {
            "& .image-holder2": {
                "& img": {
                    height: "100px",
                },
            },
            "& .geeks": {
                "& span": {
                    fontSize: "32px",
                    color: "#fff",
                    display: "inline-block",
                    letterSpacing: "5px",
                    fontWeight: 900,
                    transition: "all 0.5s",
                    animation: "animate 2s infinite",
                    "&:nth-child(1)": {
                        animationDelay: "0.1s",
                    },
                    "&:nth-child(2)": {
                        animationDelay: "0.2s",
                    },
                    "&:nth-child(3)": {
                        animationDelay: "0.3s",
                    },
                    "&:nth-child(4)": {
                        animationDelay: "0.4s",
                    },
                    "&:nth-child(5)": {
                        animationDelay: "0.5s",
                    },
                    "&:nth-child(6)": {
                        animationDelay: "0.6s",
                    },
                    "&:nth-child(7)": {
                        animationDelay: "0.7s",
                    },
                    "&:nth-child(8)": {
                        animationDelay: "0.8s",
                    },
                    "&:nth-child(9)": {
                        animationDelay: "0.9s",
                    },
                    "&:nth-child(10)": {
                        animationDelay: "1s",
                    },
                    "&:nth-child(11)": {
                        animationDelay: "1.1s",
                    },
                    "&:nth-child(12)": {
                        animationDelay: "1.2s",
                    },
                    "&:nth-child(13)": {
                        animationDelay: "1.3s",
                    },
                    "&:nth-child(14)": {
                        animationDelay: "1.4s",
                    },
                    "&:nth-child(15)": {
                        animationDelay: "1.5s",
                    },
                    "&:nth-child(16)": {
                        animationDelay: "1.6s",
                    },
                    "&:nth-child(17)": {
                        animationDelay: "1.7s",
                    },
                    "&:nth-child(18)": {
                        animationDelay: "1.8s",
                    },
                    "&:nth-child(19)": {
                        animationDelay: "1.9s",
                    },
                },
            },
        },
    },
})

const Loader23 = ({classes}) => {
    return (
        <section className={`${classes.loaderBg}`}>
            <div className={`container-fluid ${classes.LoaderMain}`}>
                <div className="content-holder text-center">
                    <div className="image-holder mb-4">
                        <img src={Image} alt="img" className="img-fluid"/>
                    </div>
                    <div className="geeks">
                        <span>M</span>
                        <span>I</span>
                        <span>L</span>
                        <span>L</span>
                        <span>S</span>
                        <span>B</span>
                        <span>E</span>
                        <span>R</span>
                        <span>R</span>
                        <span>Y</span>
                        <span>P</span>
                        <span>R</span>
                        <span>E</span>
                        <span>S</span>
                        <span>C</span>
                        <span>H</span>
                        <span>O</span>
                        <span>O</span>
                        <span>L</span>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default injectSheet(style)(Loader23);
