import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const FacebookPixel = (props) => {
  let location = useLocation();

  useEffect(() => {
    import("react-facebook-pixel")
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init(props.id);
        ReactPixel.pageView();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return null;
};

export default FacebookPixel;
