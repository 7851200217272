export default function (theme, profile) {
  return {
    webId: profile?.data.id,
    domain: profile?.data.domain,
    banner: profile?.data.banner,
    bannerHeight: profile?.data.banner_height,
    resourcePath: profile?.resource_path,
    // fonts: {
    //     url: theme.fonts.url
    //     url: "https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap"
    // },
    colors: {
      primary: theme.colors.primary,
      secondary: theme.colors.secondary,
    },
    isMobile: window.innerWidth <= 768,
  };
}
