import React from "react";

// image
import Image from "images/wave-curly-14.svg";

// react jss
import injectShet from "react-jss";

// style
const style = theme => ({
    rpsharmaDetailPageBg: {
        marginTop: "-80px",
        position: "relative",
    },
    rpsharmaDetailPageMain: {
        "& .content-holder": {
            "& .img-holder": {
                height: "100vh",
                width: "100%",
                "& .bg-stretch": {
                    position: "relative",
                    height: "100%",
                    width: "100%",
                    backgroundColor: "transparent",
                    backgroundPosition: "top center",
                    backgroundAttachment: "fixed",
                    display: "flex",
                    alignItems :"Center",
                    justifyContent: "center",
                    "&::before": {
                        content: "''",
                        position: "absolute",
                        top: 0,
                        background: "rgba(0,0,0,0.3)",
                        height: "100%",
                        width: "100%",
                    },
                    "& .text-holder": {
                        position: "relative",
                        zIndex: 9,
                        "& .title": {
                            color: "#fff",
                            fontSize: "42px",
                            fontWeight: 900,
                            padding: 0,
                            margin: 0,
                            textTransform: "uppercase",
                        },
                    },
                },
            },
        },
    },
})

const RpsharmaDetailPage = ({classes, image, title}) => {
    return (
        <section className={`${classes.rpsharmaDetailPageBg}`}>
            <div className={`${classes.rpsharmaDetailPageMain}`}>
                <div className="content-holder">
                    <div className="img-holder">
                        <div className="bg-stretch background-image cover"
                             style={{backgroundImage: `url(${image})`}}>
                            <div className="text-holder">
                                <h2 className="title">{title}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default injectShet(style)(RpsharmaDetailPage);