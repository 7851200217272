import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import { reducer as reduxFormReducer } from "redux-form";

import {
    websiteDataReducer,
    layoutReducer,
    homePageReducer,
    pageReducer,
    contentReducer,
    photoGalleryPageReducer,
    singleGalleryPageReducer,
    videoGalleryPageReducer,
    contactPageReducer,
    errorReducer, pblSingleGalleryPageReducer, pblPhotoGalleryPageReducer,
} from "./frontReducer";


export default history =>
  combineReducers({
    router: connectRouter(history),
    websiteData: websiteDataReducer,
    layout: layoutReducer,
    homePage: homePageReducer,
    page: pageReducer,
    content: contentReducer,
    photoGalleryPage: photoGalleryPageReducer,
    singleGalleryPage: singleGalleryPageReducer,
    pblPhotoGalleryPage: pblPhotoGalleryPageReducer,
    pblSingleGalleryPage: pblSingleGalleryPageReducer,
    videoGalleryPage: videoGalleryPageReducer,
    contactPage: contactPageReducer,
    form: reduxFormReducer,
    error: errorReducer
  });
