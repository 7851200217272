export const FETCH_WEBSITE_DATA = "FETCH_WEBSITE_DATA";

export const FETCH_LAYOUT_DATA = "FETCH_LAYOUT_DATA";

export const FETCH_HOME_DATA = "FETCH_HOME_DATA";

export const FETCH_PAGE_DATA = "FETCH_PAGE_DATA";
export const FETCH_SINGLEPAGE_DATA = "FETCH_SINGLEPAGE_DATA";

export const FETCH_CONTENT_DATA = "FETCH_CONTENT_DATA";

export const FETCH_NOTICE_DATA = "FETCH_NOTICE_DATA";
export const FETCH_SINGLENOTICE_DATA = "FETCH_SINGLENOTICE_DATA";

export const FETCH_EVENT_DATA = "FETCH_EVENT_DATA";
export const FETCH_SINGLEEVENT_DATA = "FETCH_SINGLEEVENT_DATA";

export const FETCH_PROGRAM_DATA = "FETCH_PROGRAM_DATA";
export const FETCH_SINGLEPROGRAM_DATA = "FETCH_SINGLEPROGRAM_DATA";

export const FETCH_BLOG_DATA = "FETCH_BLOG_DATA";
export const FETCH_SINGLEBLOG_DATA = "FETCH_SINGLEBLOG_DATA";

export const FETCH_NEWS_DATA = "FETCH_NEWS_DATA";
export const FETCH_SINGLENEWS_DATA = "FETCH_SINGLENEWS_DATA";

export const FETCH_PHOTOGALLERY_DATA = "FETCH_PHOTOGALLERY_DATA";
export const FETCH_PHOTOLIST_DATA = "FETCH_PHOTOLIST_DATA";

export const FETCH_PBL_PHOTOGALLERY_DATA = "FETCH_PBL_PHOTOGALLERY_DATA";
export const FETCH_PBL_PHOTOLIST_DATA = "FETCH_PBL_PHOTOLIST_DATA";

export const FETCH_VIDEOGALLERY_DATA = "FETCH_VIDEOGALLERY_DATA";

export const FETCH_CONTACT_DATA = "FETCH_CONTACT_DATA";

//error
export const SET_ERROR = "SET_ERROR";
