import React from 'react';
import './../content-block/home/8/index.css';
import Image from "images/dummy-image.jpg";
import ZoomOutMapRoundedIcon from '@material-ui/icons/ZoomOutMapRounded';

// import react style
import injectSheet from 'react-jss';

const styles = theme => ({
    imgHold: {
        minHeight: "250px",
        cursor: "pointer"
    },

    bgColor: {
        background: "#eee",
    },

    teamHead: {
        background: props => props.colors && props.colors.backgroundColor ? props.colors.backgroundColor : theme.colors.primary,
    },
    content:{
        padding: "10px",
        "& .name":{
            fontSize: "16px",
            fontWeight: 500,
            color: "#434343",
        },
        "& .designation":{
            color: "#73738c",
        },
    },

});

const UserCard = ({data, resource_path, toggleModal, classes, theme}) => {
    const LNA = theme.domain === "lna.jmssg.edu.np" || theme.domain === "lna.edu.np";
    return (
        <div className="person text-center mb-3">
            {
                LNA ? null : (
                    <div className={`${classes.teamHead} p-2 `}>
                <span className="title">
                    {data.designation.title}
                </span>
                    </div>
                )
            }

            <div className="info">
                <div onClick={() => toggleModal(data)} className={`img-hold ${classes.imgHold + ' ' + classes.bgColor}`}
                     style={{backgroundImage: data.image ? `url("${resource_path + data.image}")` : `url('${Image}')`}}>
                    <ZoomOutMapRoundedIcon className='zoom-icon' />
                </div>
                <div className={`${classes.content}`}>
                    <div className={`d-block name`}>{data.name}</div>
                    {
                        LNA?(
                            <span className="designation">{data.title} </span>
                        ):null
                    }
                </div>
            </div>
        </div>
    );
};

export default injectSheet(styles)(UserCard);