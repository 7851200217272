import React from 'react';
import {Link} from 'react-router-dom';

// asset import
import './Column.css';

const Column = ({base_slug, data, resource_path}) => {
    return (
        <div className="news-sec2 container-fluid">
            <div className="row">
                {
                    data.map((item, i) => {
                        return (
                            <div className="col-md-4 mb-3" key={i}>
                                <div className="bg-holder">
                                    <div className="list-holder">
                                        <div className="wrap-img"
                                             style={{backgroundImage: `url('${item.image ? resource_path + item.image : ""}')`}}/>
                                        <div className="wrap-content">
                                            <span className="title">{item.title}</span>
                                            <p>{item.summary ? (item.summary.length > 70 ? item.summary.substring(0, 70) + "..." : item.summary.substring(0, 70)) : ""}</p>
                                            <Link to={base_slug + item.slug} className="btn-link">
                                                Learn More
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};

export default Column;