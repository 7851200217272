import PageTitle from "components/page-title";
import React from "react";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  WhatsappShareButton,
  WhatsappIcon,
  ViberShareButton,
  ViberIcon,
} from "react-share";
import VisibilityIcon from "@material-ui/icons/Visibility";
import parse from "html-react-parser";

const MainData = ({
  classes,
  files,
  mainData,
  resource_path,
  isBannerImage,
  shareUrl,
  spa,
}) => {
  return (
    <div className={`col-md ${classes.mainContent}`}>
      <div className="mb-3">
        <PageTitle title={mainData.title} />
        {isBannerImage ? null : (
          <>
            {mainData.image ? (
              <img
                className="mb-3 mainImg"
                src={resource_path + mainData.image}
                alt="img"
              />
            ) : null}
          </>
        )}
        <div className="description">
          {mainData.description ? parse(mainData.description) : null}
        </div>
        {spa ? (
          <div className="buttons-holder">
            <div className="share-buttons">
              <span className="share-text">Share:</span>
              {shareUrl ? (
                <>
                  <FacebookShareButton url={shareUrl}>
                    <FacebookIcon size={32} round={true} />
                  </FacebookShareButton>
                </>
              ) : null}
              {shareUrl ? (
                <FacebookMessengerShareButton url={shareUrl}>
                  <FacebookMessengerIcon size={32} round={true} />
                </FacebookMessengerShareButton>
              ) : null}
              {shareUrl ? (
                <TwitterShareButton url={shareUrl}>
                  <TwitterIcon size={32} round={true} />
                </TwitterShareButton>
              ) : null}
              {shareUrl ? (
                <WhatsappShareButton url={shareUrl}>
                  <WhatsappIcon size={32} round={true} />
                </WhatsappShareButton>
              ) : null}
              {shareUrl ? (
                <ViberShareButton url={shareUrl}>
                  <ViberIcon size={32} round={true} />
                </ViberShareButton>
              ) : null}
            </div>
            <div className="views">
              <VisibilityIcon />
              <span className="count">{mainData.visit_count} Views</span>
            </div>
          </div>
        ) : null}
      </div>
      {files.map((file, index) => (
        <a
          href={resource_path + file}
          className="btn btn-primary mr-2"
          target="_blank"
          rel="noopener noreferrer"
          key={index}
        >
          <i className="fa fa-download mr-1" />{" "}
          {file.lastIndexOf("-file-") !== -1
            ? file.slice(0, file.lastIndexOf("-file-"))
            : "Download File"}
        </a>
      ))}
    </div>
  );
};

export default MainData;
