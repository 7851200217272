import React from "react";

import injectSheet from "react-jss";

const style = theme => ({
    aitmBannerImageBg: {
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100%",
    },
    aitmBannerImageBg2: {
        backgroundSize: "cover",
        backgroundPosition: "100% 30%",
        backgroundRepeat: "no-repeat",
        width: "100%",
    },
    "@media(max-width: 600px)": {
        aitmBannerImageBg: {
            height: "25vh",
            backgroundPosition: "center center",
            marginTop: "75px",
        },
        aitmBannerImageBg2: {
            height: "25vh",
            marginTop: "75px",
        },
    },
    "@media(min-width: 768px)": {
        aitmBannerImageBg: {
            height: "35vh",
            backgroundPosition: "top center",
            marginTop: "75px",
        },
        aitmBannerImageBg2: {
            height: "35vh",
            marginTop: "75px",
        },
    },
    "@media(min-width: 992px)": {
        aitmBannerImageBg: {
            height: "55vh",
            backgroundPosition: "100% 25%",
            marginTop: "0",
        },
        aitmBannerImageBg2: {
            height: "100vh",
            marginTop: "0",
        },
    },
})

const AitmBannerImage = ({classes, image, theme}) => {
    const isDomain = theme.domain;
    const isBannerImage = isDomain === "spa.edu.np" || isDomain === "lna.edu.np" || isDomain === "bgmsm.edu.np";
    return (
        <div className={`${isBannerImage ? `${classes.aitmBannerImageBg2}` : `${classes.aitmBannerImageBg}`}`}
             style={{backgroundImage: `url('${image}')`}}>
        </div>
    )
}

export default injectSheet(style)(AitmBannerImage);