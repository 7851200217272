import React from "react";

//component import
import Detail from 'components/page/detail/Detail';
import List from 'components/page/list/List';
import Team from 'components/page/team/Team';
import Column from 'components/page/column/Column';
import Download1 from 'themes/download/1/index';
import Faq from "../../content-block/faq";
import QAPage from "../../../containers/qa";
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import injectSheet from "react-jss";

const style = theme => ({
	detailErrorPage: {
		padding: "100px 0",
		"& .text-holder": {
			textAlign: "center",
			marginTop: "60px",
			"& .title": {
				fontSize: "22px",
				color: "#434343",
				fontWeight: 500,
				width: "60%",
				margin: "0 auto",
				marginBottom: "10px",
			},
			"& .mail": {
				"& a": {
					textDecoration: "none",
					color: theme.colors.primary,
					fontSize: "22px",
					fontWeight: 500,
					transition: "all .3s ease-out",
					"&:hover": {
						color: theme.colors.secondary,
					},
				},
			},
		},
	},
})

const PageComponent = ({content, match, contentKey, domain, socialMedia}) => {
	const journalSxc = domain === "journal.sxc.edu.np";
	const khwopaCollege = domain === "journal.khwopacollege.edu.np";

	if (contentKey === "board") {
		return <Team {...content} />;
	} else if (contentKey === "program") {
		return <Column {...content} />
	} else if (contentKey === "faq") {
		return <Faq faq={content}/>;
	} else if (contentKey === "qa") {
		return <QAPage qa={content}/>;
	} else if (contentKey === "download" || contentKey === "singleDownload") {
		return <Download1 {...content} />;
	} else {
		if (content.data.length > 1 ||
			(contentKey === 'career') || ((journalSxc || khwopaCollege) && match.params.slugMenu === 'archive')) {
			return <List {...content} match={match} contentKey={contentKey}/>;
		} else {
			return <Detail contentKey={contentKey} {...content} match={match} socialMedia={socialMedia}/>;
		}
	}
};
const Page1 = (props) => {
	const contentKey = props.contentKey;
	const content = contentKey ? props[contentKey] : props["page"];
	const {classes} = props;

	const domain = props.profile.data.domain;
	const millsberrySchool = domain === "millsberry.edu.np";
	const millsberryPreSchool = domain === "millsberry.com.np";
	const sxc = domain === "sxc.edu.np";

	return (
		<div className="async-block">
			{content.data.length ?
				<PageComponent contentKey={contentKey} content={content}
				               match={props.match}
				               socialMedia={props.socialMedia}
				               domain={domain}/> : ((millsberrySchool || sxc || millsberryPreSchool) && content.base_slug === "/career/" ? (
					<div className={`container-fluid ${classes.detailErrorPage}`}>
						<div className="text-holder">
							<h2 className="title">
								Currently we don't have any open position but if you are interested in possible future
								opportunities please send your CV to:
							</h2>
							<div className="mail">
								<a href={`${millsberryPreSchool || sxc ? (sxc?`mailto: ktm@sxc.edu.np`:`mailto: career@millsberry.com.np`) : `mailto: career@millsberry.edu.np`}`}
								   target="_blank"
								   rel="noreferrer"
								>
									<MailOutlineIcon className="mr-1"/>
									{
										millsberrySchool || millsberryPreSchool ? (
											<>
												{
													millsberrySchool ? "career@millsberry.edu.np" : "career@millsberry.com.np"
												}
											</>
										) : (
											"ktm@sxc.edu.np"
										)
									}
								</a>
							</div>
						</div>
					</div>
				) : null)}
		</div>
	);
};

export default injectSheet(style)(Page1);