import React from "react";
import { Link } from "react-router-dom";

//reactstrap import
import { Button } from "reactstrap";
import FA from "react-fontawesome";

//react-jss import
import injectStyleSheet from "react-jss";

//asset import
import lost from "../../images/lost.svg";

const style = {
  red: {
    color: "#ea0000",
    fontSize: 70
  },
  image: {
    opacity: "0.2"
  },
  "@media(min-width: 992px)": {
    imageCol: {
      maxWidth: 230
    }
  }
};

const NoMatch = ({ classes }) => {
  return (
    <section className="single-block bg-light-grey">
      <div className="container-fluid text-center">
        <h1 className={classes.red}>Oops!</h1>
        <object
          className={`py-3 ${classes.image}`}
          data={lost}
          type="image/svg+xml"
          width={150}
        >
          Lost
          </object>
        <div className="mb-4">
          Something went wrong. The page you have requested could not be
          found.
          </div>
        <Button color="primary" tag={Link} to="/">
          <FA name="long-arrow-left" /> Back to Home Page
          </Button>
      </div>
    </section>
  );
};

export default injectStyleSheet(style)(NoMatch);
