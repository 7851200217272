import React from "react";
import { Button } from "reactstrap";
import injectSheet from "react-jss";

import FA from "react-fontawesome";

const styles = {
  content: {
    fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  }
};

const Blank = ({ classes }) => {
  return (
    <div className={`${classes.content} blank-page-content single-block bg-light-grey text-center`}>
      <h1 className="mb-4">Something went wrong!</h1>
      <p>Make sure you are connected to the internet. Click the button below to reload homepage.<br />If still issue exists, contact the system administrator.</p>
      <Button color="secondary" tag="a" href="/">
        <FA name="refresh mr-1" /> Reload Homepage
      </Button>
    </div>
  );
};

export default injectSheet(styles)(Blank);
