import React, {Component} from "react";
import {connect} from "react-redux";

//action import
import {getHomePageData} from "actions/frontAction";

//component import
import PopupModal from "components/modals/index";
import RenderBlocks from "utils/RenderBlocks";
import MillsberryClzModal from "../../components/millsberry-college/millsberry-clz-modal";

class Home extends Component {
	render() {
		const hostname = (window.location.hostname.indexOf("www.") && window.location.hostname) || window.location.hostname.replace("www.", "");
		const millsberrySchool = hostname === 'millsberry.com.np';
		const millsberryClz = hostname === 'millsberry.edu.np';
		const {homePage, popup} = this.props;

		return (
			<>
				<RenderBlocks data={homePage}/>
				{
					popup.data.map((item, i) => {
						return (
							item && item.data.map((itemData, i)=>{
								return(
									<React.Fragment key={i + "_" + i}>
										{
											millsberrySchool || millsberryClz ? (
												<MillsberryClzModal
													key={i + "_" + i}
													resource_path={item.resource_path}
													item={itemData}
												/>
											) : (
												<PopupModal
													key={i + "_" + i}
													resource_path={item.resource_path}
													itemData={itemData}
												/>
											)
										}
									</React.Fragment>
								)
							})
						)
					})
				}
			</>
		);
	}

	componentDidMount() {
		if (!this.props.homePage.isLoaded) this.props.getHomePageData();
	}
}

function mapStateToProps(state) {
	return {
		homePage: state.homePage,
		popup: state.websiteData.popup,
	};
}

export default connect(mapStateToProps,
	{
		getHomePageData
	}
)(Home);
