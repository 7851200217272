import React from "react";
import { Link } from "react-router-dom";
import injectSheet from "react-jss";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FolderOpenOutlinedIcon from "@material-ui/icons/FolderOpenOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";

// style
const style = (theme) => ({
  heading: {
    fontSize: "16px",
    fontWeight: "500",
    color: "#434343",
    flexShrink: 0,
    marginBottom: "10px",
  },
  archiveListBg: {
    padding: "100px 0",
    "& .MuiTypography-root": {
      fontFamily: "unset",
    },
  },
  archiveListMain: {
    "& .content-holder": {
      "& .MuiAccordionSummary-content": {
        display: "flex",
        alignItems: "center",
        margin: 0,
        "& .MuiTypography-root": {
          fontFamily: "unset",
          fontSize: "18px",
          fontWeight: 500,
          color: theme.colors.primary,
        },
        "& .icon-holder": {
          marginRight: "10px",
          "& .MuiSvgIcon-root": {
            color: theme.colors.primary,
          },
        },
      },
      "& .MuiAccordionActions-root": {
        textAlign: "center",
        fontFamily: "unset",
      },
      "& .MuiButton-textSizeSmall": {
        height: "36px",
        border: "1px solid #eee",
        minWidth: "141px",
        color: "#3f51b5",
        fontSize: "14px",
      },
      "& .MuiPaper-elevation1": {
        boxShadow: "0 10px 50px rgb(166 209 237 / 20%)",
        padding: "30px 0px",
        borderRadius: "15px",
      },
      "& .MuiAccordionDetails-root": {
        padding: "0 16px 0",
      },
      "& .title": {
        color: "#434343",
        fontSize: "32px",
        fontWeight: 700,
        letterSpacing: "2px",
        display: "inline-block",
        position: "relative",
        "& .fa": {
          color: theme.colors.secondary,
          fontSize: "42px",
        },
        "& .bullHorn": {
          transform: "rotate(180deg) rotateX(180deg)",
        },
      },
      "& .marginBottom": {
        marginBottom: "30px",
        "& .text-holder": {
          "& .list": {
            "& ul": {
              margin: "0",
              padding: "0",
              "& li": {
                listStyle: "none",
                position: "relative",
                paddingLeft: "15px",
                "&::before": {
                  content: "'\\f178'",
                  position: "absolute",
                  top: "50%",
                  left: 0,
                  transform: "translate(0, -50%)",
                  font: "normal normal normal 14px/1 FontAwesome",
                  color: "#73738c",
                },
                "&:not(:last-child)": {
                  marginBottom: "15px",
                },
                "& a": {
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "18px",
                  color: "#737382",
                  transition: "all .3s ease-out",
                  "& .MuiSvgIcon-root": {
                    fontSize: "22px",
                    marginRight: "5px",
                  },
                  "&:hover": {
                    color: theme.colors.secondary,
                  },
                },
              },
            },
          },
        },
      },
    },
  },
});

const ArchiveList = ({ classes, data, base_slug }) => {
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const currentIssueData = data.filter((archive) => {
    return Number(archive.archived) === 1;
  });

  const result = currentIssueData.reduce(function (data, item) {
    data[item.issue_year] = data[item.issue_year] || [];
    data[item.issue_year].push(item);
    return data;
  }, Object.create(null));

  if (data) {
    const dataLength = Object.keys(result).length;
    return result && dataLength > 0 ? (
      <section className={`${classes.archiveListBg}`}>
        <div className={`container-fluid ${classes.archiveListMain}`}>
          <div className="content-holder">
            <div className="text-holder mb-5">
              <h2 className="title">Journal Archive</h2>
            </div>
            <div className={classes.root}>
              <div className="row">
                {Object.entries(result).map(([_, yearData], i) => {
                  return (
                    <div
                      className="col-lg-4 col-md-12 col-sm-12 marginBottom"
                      key={i}
                    >
                      <Accordion
                        expanded={expanded === `panel_${i}`}
                        onChange={handleChange(`panel_${i}`)}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel-header"
                        >
                          <div className="icon-holder">
                            <FolderOpenOutlinedIcon />
                          </div>
                          <Typography className={classes.heading}>
                            Volume {i + 1}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography component={"span"}>
                            <div className="text-holder">
                              <div className="list">
                                <ul>
                                  {yearData.map((yearItem, i) => {
                                    return (
                                      <li key={i++}>
                                        <Link to={base_slug + yearItem.slug}>
                                          <DescriptionOutlinedIcon />
                                          Article {i + 1}
                                        </Link>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    ) : null;
  } else return "";
};

export default injectSheet(style)(ArchiveList);
