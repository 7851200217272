import React, { useState } from 'react';
import injectSheet from "react-jss";
import { TabContent, TabPane, Nav, NavItem, NavLink, Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import classnames from "classnames";

//component import
import UserCard from './../../user-card/userCard';
import Image from "images/dummy-image.jpg";

// asset import
import "./index.css";
import HTMLReactParser from "html-react-parser";

const styles = theme => ({
    subGroupTitle: {
        fontSize: 14,
        color: theme.colors.primary
    },

    modalCloseButton: {
        position: "absolute",
        right: "5px",
        top: "3px",
        zIndex: 5,
        width: "30px",
        height: "30px",
    },

    modalSubTitle: {
        fontSize: 14,
        color: theme.colors.primary,
        textTransform: "uppercase"
    },

    designation: {
        lineHeight: "14px",
    },

    colImage: {
        maxWidth: 280
    },

    image: {
        width: 250,
        height: 250
    }
});

const Team = ({ title, data, resource_path, classes }) => {
    const [activeTab, setActiveTab] = useState('tab_1');
    const toggleTab = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const [modal, setModal] = useState(false);
    const [userData, setUserData] = useState(null);
    const toggleModal = (data) => {
        setUserData(data);
        setModal(!modal);
    };

    return (
        <>
            <div className="container-fluid">
                <h2 className="mt-5">{title}</h2>

                {data.length > 1 ? (
                    <Nav className="nav-tabs-custom">
                    {data.map((group, i) => {
                        const item = group[0][0];
                        const title = item.designation_group ? item.designation_group : "OTHER";

                        return (
                            <NavItem key={`group_${i}`}>
                                <NavLink
                                    className={classnames("text-uppercase", { active: activeTab === (`tab_${i + 1}`) })}
                                    onClick={() => { toggleTab(`tab_${i + 1}`); }}
                                >{title}</NavLink>
                            </NavItem>
                        );
                    })}
                </Nav>
                ) : null}

                <TabContent activeTab={activeTab}>
                    {data.map((group, i) => {
                        return (
                            <TabPane tabId={`tab_${i + 1}`} key={`tab_content_${i}`}>
                                {group.map((subGroup, j) => {
                                    return (
                                        <div className="sub-group mt-4" key={`tab_content_${i}_${j}`}>
                                            {subGroup[0].designation_sub_group && <div className={classnames("text-uppercase mb-2", classes.subGroupTitle)}>{subGroup[0].designation_sub_group}</div>}
                                            <div className="row mb-3">
                                                {subGroup.map((item, k) => {
                                                    item.designation = {
                                                        title: item.title
                                                    };
                                                    return <div className="col-md-3" key={`tab_content_${i}_${j}_${k}`}>
                                                        <UserCard data={item} resource_path={resource_path} imgHeight={240} toggleModal={toggleModal} />
                                                    </div>;
                                                })}
                                            </div>
                                        </div>
                                    );
                                })}
                            </TabPane>
                        );
                    })}
                </TabContent>

                {userData && <Modal isOpen={modal} toggle={() => toggleModal(null)}>
                    <button className={`close ${classes.modalCloseButton}`} onClick={() => toggleModal(null)} aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                    <ModalBody>
                        <div className="row text-center text-md-left justify-content-center">
                            <div className={classnames("col-sm mb-3 mb-md-0", classes.colImage)}>
                                <div className={classnames("background-image cover shadow", classes.image)} style={{backgroundImage: `url('${userData.image ? resource_path + userData.image : Image}')`}}/>
                            </div>
                            <div className="col-sm">
                                <h5 className="mb-1">{userData.name}</h5>
                                <div className={classes.modalSubTitle}>{userData.designation_group ? userData.designation_group : ""} {userData.designation_sub_group ? `(${userData.designation_sub_group})` : ""}</div>
                                <div className={classnames("text-muted mb-3", classes.designation)}>{userData.designation.title}</div>
                                <div className="description">{userData.about?HTMLReactParser(userData.about):null}</div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter className="justify-content-center">
                        <Button color="primary" onClick={() => toggleModal(null)}>Close</Button>
                    </ModalFooter>
                </Modal>}
            </div>
        </>
    );
};

export default injectSheet(styles)(Team);