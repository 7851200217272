import React from "react";
import { connect } from "react-redux";

//action import
import { getHomePageData } from "../../actions/frontAction";

//component import
import Loader from "components/loader/Loader";
import PopupModal from 'components/modals/index';
import RenderTheme from "../../utils/RenderTheme";

class Niranjan extends React.Component {
    render() {
        const { homePage, layout, websiteData } = this.props;
        const popup = websiteData.popup;

        const defaultBlockProps = {
            ...websiteData,
            profile: layout.profile,
            socialMedia: layout.socialMedia,
            menu: layout.menu,
            theme: layout.theme,
            usefulLink: layout.usefulLink,
        };

        return (
            <>
                {
                    homePage.isLoaded ?
                        (
                           <React.Suspense fallback="">
                             <RenderTheme themeId={"Footer1"} {...defaultBlockProps} />
                             <RenderTheme themeId={"Footer2"} {...defaultBlockProps} />
                            {/* <RenderTheme themeId={"HomeSlider3"} {...defaultBlockProps} /> */}
                            {/* <RenderTheme themeId={"Header6"} {...defaultBlockProps} /> */}
                            {/* <RenderTheme themeId={"Header5"} {...defaultBlockProps} /> */}
                            {/* <RenderTheme themeId={"Header4"} {...defaultBlockProps} /> */}
                            {/* <RenderTheme themeId={"Header4"} {...defaultBlockProps} /> */}
                           </React.Suspense>
                        ) :
                        <div className="async-block" style={{ minHeight: "40vh" }}><Loader /></div>
                }

                {/*{popup.length ?
                    popup.map((popup, i) => {
                        if (popup.data && popup.data.length) {
                            return popup.data.map((item, j) => {
                                return <PopupModal key={i + '_' + j} resource_path={popup.resource_path} itemData={item} />;
                            });
                        }
                    })
                    : null}*/}
            </>
        );
    }

    componentDidMount() {
        this.props.getHomePageData();
    }
}

function mapStateToProps(state) {
    return {
        layout: state.layout,
        homePage: state.homePage,
        websiteData: state.websiteData
    };
}

export default connect(mapStateToProps, { getHomePageData })(Niranjan);