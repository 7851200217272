import React, {useEffect, useState} from "react";

// css
import "./index.css";

// react jss
import injectSheet from "react-jss";
import Confetti from "react-confetti";

// style
const style = theme => ({
    loaderBg: {
        padding: "100px 0",
        background: "#fff",
        position: "fixed",
        // overflow: "hidden",
        height: "100vh",
        zIndex: 9999,
        width: "100%",
    },
    loaderMain: {
        "& .content-holder": {
            "& .feliz": {
                width: "100%",
                fontSize: "100px",
                fontWeight: 700,
                color: theme.colors.primary,
                textAlign: "center",
                position: "absolute",
                top: "50%",
                opacity: 0,
                animation: "vem_feliz 1s ease-in-out 2.8s forwards",
            },

        },
    },
    "@keyframes vem_feliz": {
        "0%": {
            marginTop: 0,
            opacity: 0,
            // textShadow: "0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073",
        },
        "100%": {
            marginTop: "-200px",
            opacity: 1,
            // textShadow: `0 0 20px #fff, 0 0 30px ${theme.colors.secondary}, 0 0 40px ${theme.colors.secondary}, 0 0 50px ${theme.colors.secondary}, 0 0 60px ${theme.colors.secondary}, 0 0 70px ${theme.colors.secondary}, 0 0 80px ${theme.colors.secondary}`,
        },
    },
})

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}
function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}

const Loader22 = ({classes}) => {
    const { height, width } = useWindowDimensions();
    return (
        <section className={`${classes.loaderBg}`}>
            <div className={`${classes.loaderMain}`}>
                <div className="content-holder">
                    <div className="feliz">Happy new year</div>
                    <div className="ano_novo">
                        <span>202</span>
                        <span className="seis">1</span>
                        <span className="sete">2</span>
                        <div className="balao"/>
                    </div>
                    <div className="fogos">
                        <div className="f1">
                            <span><i/></span>
                            <span><i/></span>
                            <span><i/></span>
                        </div>
                        <div className="f2">
                            <span><i/></span>
                            <span><i/></span>
                            <span><i/></span>
                        </div>
                        <div className="f3">
                            <span><i/></span>
                            <span><i/></span>
                            <span><i/></span>
                        </div>
                        <div className="f4">
                            <span><i/></span>
                            <span><i/></span>
                            <span><i/></span>
                        </div>
                    </div>
                    <Confetti
                        width={width}
                        height={height}
                    />
                </div>
            </div>
        </section>
    )
}

export default injectSheet(style)(Loader22);