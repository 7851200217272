import React, { useState } from "react";
import injectSheet from "react-jss";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MainData from "components/page/detail/Main-data";
import toast from "./../../function/notification";
import { PasswordProtectedScema } from "function/validation";
import { Formik } from "formik";
import axios from "axios";
import { Grid } from "@material-ui/core";
import { ToastContainer } from "react-toastify";

const style = (theme) => ({
  PasswordProtected: {
    padding: "100px 0",
    "& .password-protected": {
      background: "#fff",
      borderRadius: "15px",
      padding: "30px",
      maxWidth: "700px",
      margin: "0 auto",
      boxShadow: "0 10px 50px #ccc",
      "& .title": {
        fontSize: "18px",
        fontWeight: "normal",
        color: "#73738c",
      },
      "& .form": {
        "& .MuiTextField-root": {
          "& .MuiOutlinedInput-root": {
            borderRadius: "8px",
            fontFamily: "unset",
          },
        },
        "& .error-message": {
          color: "#e63946",
          marginTop: "5px",
        },
        "& .MuiButtonBase-root": {
          height: "56px",
          fontFamily: "unset",
          borderRadius: "8px",
          minWidth: "120px",
          background: theme.colors.primary,
          color: "#fff",
          boxShadow: `0 10px 50px ${theme.colors.primary}4f`,
        },
      },
    },
  },
});

const PasswordProtected = ({
  classes,
  mainData,
  resource_path,
  isBannerImage,
  shareUrl,
  spa,
  theme,
}) => {
  const [data, setData] = useState(null);

  const handleSubmit = (values, { resetForm, setSubmitting }) => {
    const url = `https://${theme.domain}/api/page/view`;

    const formData = new FormData();
    formData.append("content_id", mainData.id);
    formData.append("password", values.password);

    axios
      .post(url, formData)
      .then(({ data }) => {
        setData(data);
        setSubmitting(false);
        toast.showSuccess("File access successful");
        resetForm({
          password: "",
        });
      })
      .catch((error) => {
        setSubmitting(false);
        toast.showError(
          error.response?.data?.message || "Something went wrong!"
        );
      });
  };

  return (
    <section className={classes.PasswordProtected}>
      <div className="container">
        {!data ? (
          <div className="password-protected">
            <h1 className="title mb-4">
              This content is password protected. To view it please enter your
              password below:
            </h1>
            <Formik
              initialValues={{
                password: "",
              }}
              validationSchema={PasswordProtectedScema}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) =>
                isSubmitting ? (
                  <h1>Loading.....</h1>
                ) : (
                  <form
                    className="form"
                    noValidate
                    autoComplete="off"
                    onSubmit={(event) => {
                      handleSubmit(event);
                    }}
                  >
                    <Grid container spacing={6}>
                      <Grid item sm={12} lg={9}>
                        <TextField
                          variant="outlined"
                          label="Password"
                          type="password"
                          name="password"
                          fullWidth
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                        />
                        {errors.password && touched.password ? (
                          <div className="error-message">{errors.password}</div>
                        ) : null}
                      </Grid>
                      <Grid item sm={12} lg={3}>
                        <Button type="submit" variant="contained">
                          Enter
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                )
              }
            </Formik>
          </div>
        ) : (
          <MainData
            files={data.file ? data.file.split(",") : []}
            mainData={data}
            resource_path={resource_path}
            shareUrl={shareUrl}
            spa={spa}
            isBannerImage={isBannerImage}
            classes={classes}
          />
        )}
      </div>
      <ToastContainer />
    </section>
  );
};

export default injectSheet(style)(PasswordProtected);
