import React from "react";
import sandip from "./sandip";
import niranjan from "./niranjan";

//header
const Header1 = React.lazy(() => import("./header/1/index"));
const Header2 = React.lazy(() => import("./header/2/index"));
const Header3 = React.lazy(() => import("./header/3/index"));
const headers = {
    Header1,
    Header2,
    Header3,
};

//footer
const Footer1 = React.lazy(() => import("./footer/1/index"));
const footers = {
    Footer1,
};

//page
const Page1 = React.lazy(() => import("./page/1/index"));
const pages = {
    Page1,
};

//contact
const Contact1 = React.lazy(() => import("./contact/1/index"));
const Contact2 = React.lazy(() => import("./contact/2/index"));
const contacts = {
    Contact1,
    Contact2,
};

//content block
const ContentBlock1 = React.lazy(() => import("./content-block/_other/1/index"));

//home slider
const HomeSlider1 = React.lazy(() => import("./content-block/home-slider/1/index"));
const HomeSlider2 = React.lazy(() => import("./content-block/home-slider/2/index"));

//Scrolling Notice
const ScrollingNotice1 = React.lazy(() => import("./content-block/scrolling-notice/1/index"));

//google map
const GoogleMap1 = React.lazy(() => import("./content-block/google-map/1/index"));

//testimonial slider
const TestimonialSlider1 = React.lazy(() => import("./content-block/testimonial-slider/1/index"));
const TestimonialSlider2 = React.lazy(() => import("./content-block/testimonial-slider/2/index"));

// event slider
const EventSlider1 = React.lazy(() => import("./content-block/event-slider/1/index"));
const EventSlider2 = React.lazy(() => import("./content-block/event-slider/2/index"));

// course slider
const CourseSlider1 = React.lazy(() => import("./content-block/course-slider/1/index"));
const CourseSlider3 = React.lazy(() => import("./content-block/course-slider/3/index"));

const contentBlocks = {
    ContentBlock1,

    HomeSlider1,
    HomeSlider2,

    ScrollingNotice1,

    GoogleMap1,

    TestimonialSlider1,
    TestimonialSlider2,

    EventSlider1,
    EventSlider2,

    CourseSlider1,
    CourseSlider3,
};

// Photo gallery
const PhotoGallery1 = React.lazy(() => import("./photo-gallery/1/index"));
const PhotoGallery2 = React.lazy(() => import("./photo-gallery/2/index"));
const PblPhotoGallery = React.lazy(() => import("./pbl-photo-gallery/1"));
const photoGalleries = {
    PhotoGallery1,
    PhotoGallery2,
    PblPhotoGallery
};

// Photo List
const PhotoList1 = React.lazy(() => import("./photo-list/1/index"));
const PblGalleryDetail = React.lazy(() => import("./pbl-gallery-detail/1"));
const photoLists = {
    PhotoList1,
    PblGalleryDetail
};

// Video gallery
const VideoGallery1 = React.lazy(() => import("./video-gallery/1/index"));
const videoGalleries = {
    VideoGallery1,
};

// Other Themes
const CAOHome = React.lazy(() => import("./_other/cao-home/index"));
const WhiteHouseHome = React.lazy(() => import("./_other/whitehouse-home/index"));
const others = {
    CAOHome,
    WhiteHouseHome
};

// setting up themes object
const Themes = {
    ...headers,
    ...footers,
    ...pages,
    ...contacts,
    ...contentBlocks,
    ...photoGalleries,
    ...photoLists,
    ...videoGalleries,
    ...others,
    ...sandip,
    ...niranjan,
};

export default Themes;