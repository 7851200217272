import axios from 'axios';

const hostNames = new Map([
  ['Millsberrry_School', 'millsberry.edu.np'],
  ['SXC_Loyala', 'loyola.sxc.edu.np'],
  ['Jmssg', 'jmssg.edu.np'],
  ['Spa', 'spa.edu.np'],
  ['Bgmsm', 'bgmsm.edu.np'],
]);

const isLocalhost = (hostname) => {
  return hostname === 'localhost' || hostname === '127.0.0.1' || hostname.startsWith('192.168.');
};

const getHostName = () => {
  let hostname = window.location.hostname;
  if (hostname.startsWith('www.')) {
    hostname = hostname.slice(4);
  }
  return hostname;
};

class Api {
  constructor() {
    this.base_url = this.getBaseUrl();
    this.headers = {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    };
  }

  getBaseUrl() {
    let hostname = getHostName();
    if (isLocalhost(hostname)) {
      hostname = '127.0.0.1:8000';
      hostname = hostNames.get('SXC_Loyala');
    }
    return window.location.protocol + '//' + hostname + '/api';
  }

  async get(path, params) {
    const url = `${this.base_url}${path}`;
    try {
      const response = await axios.get(url, { params: { client: this.hostname, data: params } });
      return response.data;
    } catch (error) {
      // console.error(error);
      // throw error;
    }
  }

  async post(path, data) {
    const url = `${this.base_url}${path}`;
    try {
      const response = await axios.post(url, {
        headers: this.headers,
        client: this.hostname,
        body: data,
      });
      return response.data;
    } catch (error) {
      // console.error(error);
      // throw error;
    }
  }

  async create(path, data) {
    return this.post(path, data);
  }
}

export default Api;
